import { render, staticRenderFns } from "./TagForm.vue?vue&type=template&id=6b2ebc67&"
import script from "./TagForm.js?vue&type=script&lang=js&"
export * from "./TagForm.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIcon,QCircularProgress,QInput,QToggle,QSelect,QChip});
