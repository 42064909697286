/* eslint-disable camelcase */
import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import DpGridStore from 'src/store/dp-grid/index'
import TagModel from 'src/model/veiculo/TagModel.js'
import TagRelacaoModel from 'src/model/veiculo/TagRelacaoModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import Upload from 'src/components/Upload.vue'

import CategoriaModel from 'src/model/veiculo/CategoriaModel.js'
import CarroceriaModel from 'src/model/veiculo/CarroceriaModel.js'
import MarcaModel from 'src/model/veiculo/MarcaModel.js'
import ModeloModel from 'src/model/veiculo/ModeloModel.js'
import VersaoModel from 'src/model/veiculo/VersaoModel.js'
import LocalModel from 'src/model/usuario/LocalModel.js'
import TipoNegociacaoModel from 'src/model/veiculo/TipoNegociacaoModel.js'
import BemModel from 'src/model/veiculo/BemModel.js'
import { clone } from 'quasar'

export default {
  name: 'CategoriaForm',
  components: { DpForm, Upload },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      modelPadrao: new TagModel(),
      modelRelacao: new TagRelacaoModel(this.$route.params.id),
      carregamentos: [],

      relacoes: [],
      categorias: [],
      carrocerias: [],
      marcas: [],
      modelos: [],
      versoes: [],
      locais: [],
      tiposNegociacoes: [],
      bens: [],
      ordem: ['categorias', 'carrocerias', 'marcas', 'modelos', 'versoes', 'locais', 'tiposNegociacoes', 'bens'],
      buscandoTodosItens: false,
      buscandoRelacoes: false,
      salvandoRelacoes: false
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  mounted () {
    this.carregamentos = this.ordem.map(_ => false)
    if (this.$route.params.id) {
      this.buscarComponentesRelacao()
      this.buscarRelacoes()
    }
  },
  methods: {
    salvarRelacoes () {
      this.salvandoRelacoes = true
      let form = clone(this.modelRelacao.form)
      form.id = null
      form.tag_id = this.$route.params.id
      this.modelRelacao.salvar(form).then((response) => {
        this.relacoes = this.relacoes.concat(response.dados)
        this.salvandoRelacoes = false
        this.notificacao('salvo', 'Relações salvas')
      }).catch((error) => {
        this.notificacao('error', error.msg)
        this.salvandoRelacoes = false
      })
    },
    setarImagem (e) {
      this.modelPadrao.form.foto = e.arquivo
      this.modelPadrao.form.foto_url_completa = e.arquivo.arquivo_url_completa
    },
    filtroTagRelacao (id) {
      return this.relacoes ? clone(this.relacoes).filter(item => item[id] > 0) : []
    },
    deletarTagRelacao (id) {
      this.modelRelacao.deletar(id).then(() => {
        let index = this.relacoes.findIndex(r => r.id === id)
        if (index >= 0) {
          let _r = clone(this.relacoes)
          _r.splice(index, 1)
          this.relacoes = _r
        }
      })
    },
    buscarRelacoes () {
      this.buscandoRelacoes = true
      this.modelRelacao.getListagem({ params: { search: `tag_id:${this.$route.params.id}` } }).then((response) => {
        this.relacoes = response.dados ?? []
        this.buscandoRelacoes = false
      }).catch(() => {
        this.buscandoRelacoes = false
      })
    },
    buscarComponentesRelacao () {
      const model_categorias = new CategoriaModel()
      const model_carrocerias = new CarroceriaModel()
      const model_marcas = new MarcaModel()
      const model_modelos = new ModeloModel()
      const model_versoes = new VersaoModel()
      const model_locais = new LocalModel()
      const model_tiposNegociacoes = new TipoNegociacaoModel()
      const model_bens = new BemModel()
      const models = [model_categorias, model_carrocerias, model_marcas, model_modelos, model_versoes, model_locais, model_tiposNegociacoes, model_bens]
      const ordem = ['categorias', 'carrocerias', 'marcas', 'modelos', 'versoes', 'locais', 'tiposNegociacoes', 'bens']
      const promises = ordem.map((_, index) => {
        return models[index].getListagem()
      })
      this.buscandoTodosItens = true
      Promise.all(promises).then((dados) => {
        dados.forEach((res, index) => {
          this[this.ordem[`${index}`]] = res.dados
        })
        this.buscandoTodosItens = false
      })
    },
    buscarListagem (tipo) {
      const model_categorias = new CategoriaModel()
      const model_carrocerias = new CarroceriaModel()
      const model_marcas = new MarcaModel()
      const model_modelos = new ModeloModel()
      const model_versoes = new VersaoModel()
      const model_locais = new LocalModel()
      const model_tiposNegociacoes = new TipoNegociacaoModel()
      const model_bens = new BemModel()
      const models = [model_categorias, model_carrocerias, model_marcas, model_modelos, model_versoes, model_locais, model_tiposNegociacoes, model_bens]

      let index = this.ordem.findIndex(t => tipo === t)

      this.carregamentos[index] = false
      models[index].getListagem().then((response) => {
        this.carregamentos[index] = false
        this[`${tipo}`] = response.dados
      }).catch((error) => {
        this.notificacao('erro', error.msg)
      })
    }
  }
}
