import PadraoModel from 'src/core/model/PadraoModel.js'

export default class TagRelacaoModel extends PadraoModel {
  recurso = 'tags-relacoes';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Relações das tags';

  colunasGrid = [];

  form = {
    id: null,
    tag_id: null,
    categoria_id: null,
    carroceria_id: null,
    marca_id: null,
    modelo_id: null,
    versao_id: null,
    comitente_id: null,
    tipo_negociacao_id: null,
    bem_id: null
  };

  constructor (id) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    this.form.tag_id = id
  }
}
